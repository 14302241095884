export const designers = [
  "AJAY",
  "431:88 by Shweta Kapur",
  "Abhinav Mishra",
  "Abhishek Gupta Benares",
  "Abraham & Thakore",
  "Ahmev",
  "Aisha Rao",
  "Ajiesh Oberoi",
  "Akaaro",
  "Amisha Kothari",
  "Amit Aggarwal",
  "Amit GT",
  "Amitabh Malhotra",
  "AMPM",
  "Amrita Thakur",
  "Anamika Khanna",
  "Aneesh Agarwaal",
  "Anita Dongre",
  "Anita Dongre Grassroot",
  "Ankur J",
  "Anushree Reddy",
  "Archana Jaju",
  "Archana Shah",
  "Aseem Kapoor",
  "Astha Narang",
  "Avvantikaa S Parwani",
  "Baise Gaba",
  "Balance by Rohit Bal",
  "Begum Pret",
  "Bhawna Rao",
  "Bhumika Sharma",
  "Bohame",
  "Chandrima",
  "Charkhee",
  "Chhavvi Aggarwal",
  "Chokhi Chorri",
  "Chola",
  "Cord Studio",
  "Corpora Studio",
  "Couture by Niharika",
  "Daman J Singh",
  "Deepthee",
  "Delhi Vintage Co.",
  "Dev R Nil",
  "Devnaagri",
  "Devyani Mehrotra",
  "Dhaari by Deepika",
  "Disha Patil",
  "Divyam Mehta",
  "DiyaRajvvir",
  "DOT",
  "Drishti & Zahabia",
  "Dyelogue by Rachita Parekh",
  "Ekaya",
  "Ekru",
  "Esha Koul",
  "Esha Sethi Thirani",
  "Gaurav Gupta",
  "Gazal Mishra",
  "Gopi Vaid",
  "Gulabo by Abu Sandeep",
  "Heena Kochhar",
  "Hemang Agrawal",
  "House of Kotwara",
  "House of Pink",
  "House of Three",
  "Itrh",
  "Izzumi Mehta",
  "JADE by Monica and Karishma",
  "Jajaabor",
  "Jayanti Reddy",
  "Jayati Goenka",
  "Julie by Julie Shah",
  "Jyoti Sachdev Iyer",
  "Ka-Sha",
  "Kaia",
  "Kamaali Couture",
  "Kanelle",
  "Kanika Sharma",
  "Kapraãha",
  "Khara Kapas",
  "Kiran Uttam Ghosh",
  "KoAi",
  "Koasheé by Shubhitaa",
  "Kshitij Jalori",
  "Kunal Rawal",
  "Kunwar Rani Ritu",
  "Kyra by Mandira Wirk",
  "Label Earthen",
  "Maayera Jaipur",
  "Mahima Mahajan",
  "Maison Shefali",
  "Mandira Wirk",
  "Manish Malhotra",
  "Masaba",
  "Matsya",
  "Megha & Jigar",
  "Meghna Panchmatia",
  "MimamsAa by Ankita Singh",
  "Mishru",
  "Monika Nidhii",
  "Monisha Jaising",
  "Mrunalini Rao",
  "Mulmul",
  "Musal",
  "Mynah's Reynu Taandon",
  "Myoho",
  "Naaz by Noor",
  "Nadine",
  "Nakul Sen",
  "Neha Poddar",
  "Neha Tarun",
  "Nesolo",
  "Nikasha",
  "Nikhita Tandon",
  "Nikita Mhaisalkar",
  "Nitya Bajaj",
  "Not So Serious by Pallavi Mohan",
  "Not so serious by Pallavi Mohan",
  "Ohaila Khan",
  "Osaa by Adarsh",
  "Pallavi Jaipur",
  "Papa Don't Preach",
  "Parul and Preyanka",
  "Payal Pratap",
  "Payal Singhal",
  "Pink City by Sarika",
  "Pink Peacock Couture",
  "Pooja Peshoria",
  "Pooja Shroff",
  "Poonam Dubey Designs",
  "Prama by Pratima Pandey",
  "Pranay Baidya",
  "Prathyusha Garimella",
  "Pratibha Sultania",
  "Prémya by Manishii",
  "Priti Prashant",
  "Priyanka Jain",
  "PS by Payal Singhal",
  "Punit Balana",
  "Purvi Doshi",
  "Qbik",
  "Rabani & Rakha",
  "Raffughar by Wajahat Rather",
  "Rajdeep Ranawat",
  "Rajiramniq",
  "RAR Studio",
  "Rashi Jain",
  "Reeti Arneja",
  "Ridhi Mehra",
  "Ridhima Bhasin",
  "Rimzim Dadu",
  "Rishi & Vibhuti",
  "Ritu Kumar",
  "Rocky Star",
  "Rohit Bal",
  "Rohit Gandhi + Rahul Khanna",
  "Saaksha & Kinni",
  "Sabyasachi",
  "Sachkiran Bimbra",
  "Saksham & Neharicka",
  "Salian by Anushree",
  "Samant Chauhan",
  "Sana Barreja",
  "Sangeeta Kilachand",
  "Saundh",
  "Sawan Gandhi",
  "Scarlet Sage",
  "Seema Gujral",
  "Seema Thukral",
  "Shantanu & Nikhil",
  "Sharnita Nandwana",
  "Sheetal Batra",
  "Shehlaa Khan",
  "Shikha Mehta",
  "Shilpsutra",
  "Shivan & Narresh",
  "Shivani Bhargava",
  "Shloka Khialani",
  "Shriya Som",
  "Shruti Sancheti",
  "Shyam Narayan Prasad",
  "Siddartha Tytler",
  "Siddhartha Bansal",
  "Soup by Sougat Paul",
  "Studio Rigu",
  "Sue Mue",
  "Sukriti & Aakriti",
  "Sunira",
  "Sunita Shanker",
  "Surbhi Gupta",
  "Surendri by Yogesh Chaudhary",
  "SVA / Sonam and Paras Modi",
  "Swati Vijaivargie",
  "Tahweave",
  "Tarun Tahiliani",
  "The Boozy Button",
  "Tilla",
  "Torani",
  "Trisvaraa",
  "Twenty Nine",
  "Umbar",
  "Vaayu",
  "VARQ by Varun Nidhika",
  "Varun Bahl",
  "Varun Chakkilam",
  "Varun Nidhika",
  "Vibrance by Ananya",
  "Vineet Rahul",
  "Vrinda by Pundrik Dubey",
  "Vvani by Vani Vats",
  "Weaver Story",
];
