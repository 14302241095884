
<template>
  <div>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="true"
    />

    <b-row>
      <b-col xl="12" md="12" v-if="!sellerVerified">
        <b-alert variant="primary" show>
          <!-- <h4 class="alert-heading">
            Hey {{ userInfo.user_firstname }} ! Your account is not verified
            yet, kindly verify your account.
          </h4> -->
          <div class="alert-body">
            <span
              >Hey {{ userInfo.user_firstname }} ! Your account is not verified
              yet, kindly verify your account.</span
            >
            <b-button
              class="ml-2"
              size="sm"
              variant="primary"
              @click="sendVerificatoin()"
            >
              Send Verification Link
            </b-button>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <validation-observer ref="itemFormsValidator">
      <b-card no-body class="p-2">
        <b-form
          @submit.prevent="repeateAgain"
          ref="form"
          enctype="multipart/form-data"
          method="post"
        >
          <input
            v-show="false"
            ref="image-input"
            type="file"
            accept="image/*"
            @change="uploadNewImage($event)"
          />

          <app-collapse accordion :type="collapseType">
            <app-collapse-item
              v-for="(item, index) in item_forms"
              :id="index"
              :key="'inout-card-s' + index"
              :title="'Item ' + (index + 1)"
              :isVisible="true"
            >
              <div no-body class="p-1 item_forms_card shadow-none" ref="row">
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <b-button-group class="w-100">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          v-bind:class="[
                            item.itemType == 'sell' ? 'active' : '',
                          ]"
                          v-on:click="changeItemType('sell', index)"
                        >
                          SELL YOUR ITEM
                        </b-button>
                        <!-- <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-bind:class="[
                            item.itemType == 'refurbish' ? 'active' : '',
                          ]"
                          v-on:click="changeItemType('refurbish', index)"
                          variant="outline-primary"
                        >
                          REFURBISH YOUR ITEM
                        </b-button> -->
                      </b-button-group>
                    </b-form-group>
                  </b-col>

                  <b-col md="6"></b-col>

                  <!-- NEW IMAGES CARDs -->
                  <b-col md="6" sm="3" lg="2" xl="2" xs="2">
                    <div
                      class="shadow-none choose-images-card"
                      v-if="item.frontImage == ''"
                    >
                      <a class="fileContainer">
                        <h5 @click="setImgType('FRONT', index)">
                          * Front Image
                        </h5>
                      </a>

                      <feather-icon
                        icon="InfoIcon"
                        style="color: #a96a37; top: 10%"
                        class="info-icon image-required-info"
                        v-b-popover.hover.top="'Front Image is required'"
                        v-if="item.frontImageRequired"
                      />
                    </div>

                    <div
                      class="shadow-none choose-images-card"
                      v-if="item.frontImage != ''"
                    >
                      <b-img
                        :ref="'front_image' + index"
                        rounded
                        :src="item.frontImage"
                      />
                      <span class="delete"
                        ><span
                          @click="removeNewImage('FRONT', index)"
                          class="remove-icon"
                          >Remove</span
                        ></span
                      >
                    </div>
                  </b-col>

                  <b-col md="6" sm="3" lg="2" xl="2" xs="2">
                    <div
                      class="shadow-none choose-images-card"
                      v-if="item.backImage == ''"
                    >
                      <a class="fileContainer">
                        <h5 @click="setImgType('BACK', index)">* Back Image</h5>
                      </a>

                      <feather-icon
                        icon="InfoIcon"
                        style="color: #a96a37; top: 10%"
                        class="info-icon image-required-info"
                        v-b-popover.hover.top="'Back Image is required'"
                        v-if="item.backImageRequired"
                      />
                    </div>

                    <div
                      class="shadow-none choose-images-card"
                      v-if="item.backImage != ''"
                    >
                      <b-img
                        :ref="'back_image' + index"
                        rounded
                        :src="item.backImage"
                      />
                      <span class="delete"
                        ><span
                          @click="removeNewImage('BACK', index)"
                          class="remove-icon"
                          >Remove</span
                        ></span
                      >
                    </div>
                  </b-col>

                  <b-col md="6" sm="3" lg="2" xl="2" xs="2">
                    <div
                      class="choose-images-card"
                      v-if="item.insideImage == ''"
                    >
                      <a class="fileContainer">
                        <h5 @click="setImgType('INSIDE', index)">
                          * Side Image
                        </h5>
                      </a>

                      <feather-icon
                        icon="InfoIcon"
                        style="color: #a96a37; top: 10%"
                        class="info-icon image-required-info"
                        v-b-popover.hover.top="'Side Image is required'"
                        v-if="item.sideImageRequired"
                      />
                    </div>

                    <div
                      class="choose-images-card"
                      v-if="item.insideImage != ''"
                    >
                      <b-img
                        :ref="'inside_image' + index"
                        rounded
                        :src="item.insideImage"
                      />
                      <span class="delete"
                        ><span
                          @click="removeNewImage('INSIDE', index)"
                          class="remove-icon"
                          >Remove</span
                        ></span
                      >
                    </div>
                  </b-col>

                  <b-col md="6" sm="3" lg="2" xl="2" xs="2">
                    <div
                      class="choose-images-card"
                      v-if="item.labelImage == ''"
                    >
                      <a class="fileContainer">
                        <h5 @click="setImgType('LABEL', index)">
                          * Label Image
                        </h5>
                      </a>

                      <feather-icon
                        icon="InfoIcon"
                        style="color: #a96a37; top: 10%"
                        class="info-icon image-required-info"
                        v-b-popover.hover.top="'Label Image is required'"
                        v-if="item.labelImageRequired"
                      />
                    </div>

                    <div
                      class="choose-images-card"
                      v-if="item.labelImage != ''"
                    >
                      <b-img
                        :ref="'label_image' + index"
                        rounded
                        :src="item.labelImage"
                      />
                      <span class="delete"
                        ><span
                          @click="removeNewImage('LABEL', index)"
                          class="remove-icon"
                          >Remove</span
                        ></span
                      >
                    </div>
                  </b-col>

                  <b-col md="6" sm="3" lg="2" xl="2" xs="2">
                    <div
                      class="choose-images-card"
                      v-if="item.defectImage == ''"
                    >
                      <a class="fileContainer">
                        <h5 @click="setImgType('DEFECT', index)">
                          Defect Image (Optoinal)
                        </h5>
                      </a>
                    </div>

                    <div
                      class="choose-images-card"
                      v-if="item.defectImage != ''"
                    >
                      <b-img
                        :ref="'defect_image' + index"
                        rounded
                        :src="item.defectImage"
                      />
                      <span class="delete"
                        ><span
                          @click="removeNewImage('DEFECT', index)"
                          class="remove-icon"
                          >Remove</span
                        ></span
                      >
                    </div>
                  </b-col>

                  <!-- Offset Col-4 -->
                  <b-col md="2"></b-col>

                  <!-- MainCategory -->
                  <b-col md="3">
                    <b-form-group>
                      <div class="custom-container">
                        <label class="m-0" for="disclaimer">Category</label>
                        <validation-provider
                          #default="{ errors }"
                          name="MainCategory"
                          rules="required"
                        >
                          <v-select
                            v-model="item.mainCategorySelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="MainCategory"
                            :options="mainCategory"
                            placeholder="* Select Category"
                            @input="chooseMaincategory"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-form-group>
                  </b-col>

                  <!-- Product - Item Type -->
                  <b-col md="3">
                    <b-form-group>
                      <label class="m-0" for="disclaimer">Item Type</label>
                      <validation-provider
                        #default="{ errors }"
                        name="ItemType"
                        rules="required"
                      >
                        <!-- <v-select
                          v-model="item.typeSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="tag_name"
                          :options="clothType"
                          placeholder="* Item Type"
                        /> -->

                        <v-select
                          id="item-type"
                          v-model="item.typeSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="ItemType"
                          :disabled="currentSubCategory.length == 0"
                          :options="currentSubCategory"
                          placeholder="* Item Type"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Designer Name -->
                  <b-col md="6">
                    <b-form-group>
                      <label class="m-0" for="disclaimer">Designer</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Designer"
                        rules="required"
                      >
                        <v-select
                          v-model="item.designerSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="tag_name"
                          :options="designersList"
                          placeholder="* Designer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Condition -->
                  <b-col md="3">
                    <b-form-group>
                      <div class="custom-container">
                        <label class="m-0" for="disclaimer">Condition</label>
                        <validation-provider
                          #default="{ errors }"
                          name="Condition"
                          rules="required"
                        >
                          <v-select
                            v-model="item.conditionSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="tag_name"
                            :options="conditionsList"
                            placeholder="* Select Condition"
                          />

                          <feather-icon
                            icon="InfoIcon"
                            class="info-icon info_icon_1 mr-3"
                            v-b-modal.condition-modal
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-form-group>
                  </b-col>

                  <!-- Color -->
                  <b-col md="3">
                    <b-form-group>
                      <label class="m-0" for="disclaimer">Color</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Color"
                      >
                        <v-select
                          v-model="item.color"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="colors"
                          label="title"
                          placeholder="* Color"
                        >
                          <!-- <span> {{ title }}</span> -->
                        </v-select>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="4" v-if="item.itemType === 'sell'">
                    <b-form-group>
                      <label class="m-0" for="disclaimer"
                        >Original Purchase Price (Approx)</label
                      >
                      <b-row>
                        <b-col cols="3" class="mr-0 pr-0">
                          <b-form-select
                            v-model="item.currenciesAmountSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="Currency"
                            placeholder="* Currency"
                            @change="
                              getSelectedCurrency(
                                item.currenciesAmountSelected,
                                index
                              )
                            "
                          >
                            <b-form-select-option
                              v-for="(item_data, index) in $store.state.home
                                .currency"
                              :key="'currency_' + index"
                              :value="item_data.c_id"
                            >
                              {{ item_data.c_symbol }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-col>

                        <b-col cols="9" class="ml-0 pl-0">
                          <validation-provider
                            #default="{ errors }"
                            name="Original Purchase Price"
                            rules="required"
                          >
                            <div class="custom-container">
                              <feather-icon
                                icon="InfoIcon"
                                class="info-icon"
                                v-b-popover.hover.top="
                                  'Please provide approximate Purchase Price'
                                "
                              />
                              <b-form-input
                                placeholder="* Original Purchase Price"
                                v-model="item.amount"
                              />
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group>
                      <label class="m-0" for="disclaimer">Size</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Size"
                        rules="required"
                      >
                        <v-select
                          v-model="item.sizes"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="Size"
                          :options="cloth_sizes"
                          placeholder="* Select Size"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group
                      label-for="countries-select"
                      invalid-feedback="Country is required"
                    >
                      <label class="m-0" for="disclaimer">Country</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <v-select
                          id="countries-select"
                          v-model="item.countryModel"
                          label="name"
                          :options="countries"
                          @input="showCity"
                          required
                          placeholder="* Item Pickup Country"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group
                      label-for="city-select"
                      invalid-feedback="City is required"
                    >
                      <label class="m-0" for="disclaimer">City</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <v-select
                          id="city-select"
                          v-model="item.cityModel"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :disabled="city_list.length == 0"
                          :options="city_list"
                          required
                          placeholder="* Item Pickup City"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Upload Receipt [IMAGE -or- PDF] -->
                  <b-col md="4" v-if="item.itemType === 'sell'">
                    <b-form-group>
                      <label class="m-0" for="disclaimer"
                        >Upload Purchase Receipt (Optional)</label
                      >
                      <b-form-file
                        type="file"
                        name="receipt"
                        accept="image/*, .pdf"
                        :id="'receipt-' + index"
                        placeholder="Upload Purchase Reciept (Optional)"
                        v-on:change="uploadReceipt($event, index)"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Purchase Year -->
                  <b-col md="2" v-if="item.itemType === 'sell'">
                    <b-form-group>
                      <label class="m-0" for="disclaimer">Purchase Year (Approx)</label>
                      <div class="custom-container">
                        <feather-icon
                          icon="InfoIcon"
                          class="info-icon"
                          v-b-popover.hover.top="
                            'Please provide an approximate Purchase Year'
                          "
                        />
                        <b-form-input
                          type="number"
                          onKeyPress="if(this.value.length==4) return false;"
                          maxlength="4"
                          minlength="4"
                          v-model="item.date_of_purchase"
                          placeholder="Purchase Year"
                        />
                      </div>
                    </b-form-group>
                  </b-col>

                  <!-- Product Description -->
                  <b-col md="12" v-if="item.itemType === 'sell'">
                    <b-form-group>
                      <label class="m-0" for="disclaimer">Description</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <b-form-input
                          v-model="item.product_description"
                          placeholder="* Product Description - Eg: Cape-effect belted crepe mini dress"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    sm="12"
                    md="12"
                    lg="12"
                    v-if="item.itemType === 'sell'"
                  >
                    <b-row>
                      <b-col sm="12" md="12" lg="12">
                        <div class="demo-inline-spacing">
                          <b-form-checkbox
                            v-model="item.charitySelected"
                            class="custom-control-primary"
                          >
                            Do you wish to Donate your Item?
                          </b-form-checkbox>

                          <v-select
                            v-if="item.charitySelected"
                            style="width: 300px"
                            v-model="item.charity"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="charities"
                            label="title"
                            placeholder="* Select Charity"
                          >
                          </v-select>
                        </div>
                      </b-col>
                      <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        v-if="item.charitySelected"
                      >
                        <div class="demo-inline-spacing">
                          <b-form-checkbox
                            v-model="item.celebritySelected"
                            class="custom-control-primary"
                          >
                            Is the item from a Celebrity Closet?
                          </b-form-checkbox>

                          <v-select
                            v-if="item.celebritySelected"
                            style="width: 300px"
                            v-model="item.celebrity"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="celebrities"
                            label="title"
                            placeholder="* Select Celebrity"
                          >
                          </v-select>

                          <!-- <b-button
                            class=""
                            variant="primary"
                            v-b-modal.modal-lg-choose-celebrity
                          >
                            {{ keyword ? keyword : "Choose Celebrity" }}
                          </b-button> -->
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>

                  <!-- What customisation you'd like? -->
                  <b-col md="3" v-if="item.itemType === 'refurbish'">
                    <b-form-group>
                      <label class="m-0" for="disclaimer"
                        >Choose Customisation</label
                      >
                      <v-select
                        v-model="item.service"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="service_name"
                        :options="$store.state.home.services"
                        placeholder="What customisation you'd like?"
                        @input="setServiceId($event, index)"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Revivify Service Amount -->
                  <b-col
                    md="3"
                    v-if="item.service_id && item.itemType === 'refurbish'"
                  >
                    <b-form-group>
                      <label class="m-0" for="disclaimer"
                        >Revivify Base Service Amount</label
                      >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-form-select
                            v-model="item.currenciesBudgetSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="Currency"
                            :options="currencies"
                            placeholder="Currency"
                            @change="convertCurrency(index)"
                          />
                        </b-input-group-prepend>
                        <!-- :value="item.service.service_base_price" -->
                        <b-form-input
                          placeholder="Revivify Base Service Amount"
                          :value="
                            item.service_converted_price == 0
                              ? Math.round(
                                  item.service.service_base_price
                                ).toFixed(2)
                              : Math.round(
                                  item.service_converted_price
                                ).toFixed(2)
                          "
                          disabled
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <!-- What is your Budget Limit? -->
                  <b-col
                    md="3"
                    v-if="item.service_id && item.itemType === 'refurbish'"
                  >
                    <b-form-group>
                      <label class="m-0" for="disclaimer"
                        >* Maximum Budget Amount</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        name="BudgetAmount"
                        rules="required"
                      >
                        <b-input-group>
                          <b-input-group-prepend>
                            <b-button variant="outline-primary">
                              {{ item.currenciesBudgetSelected }}
                            </b-button>
                          </b-input-group-prepend>
                          <b-form-input
                            type="number"
                            v-model.number="item.budget"
                            placeholder="What is your Budget Limit?"
                            v-model="item.budget"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Preferred Date of Delivery -->
                  <b-col md="5" v-if="item.itemType === 'refurbish'">
                    <b-form-group>
                      <label class="m-0" for="disclaimer"
                        >Note: Date of delivery 2 weeks from now</label
                      >
                      <b-form-datepicker
                        id="example-datepicker"
                        v-model="item.delivery_date"
                        placeholder="Preferred Date of Delivery"
                        :min="min"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="2" v-if="index != 0">
                    <b-button
                      variant="secondary"
                      type="submit"
                      class="btn-block"
                      @click="removeItem(index)"
                    >
                      Remove
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </app-collapse-item>
          </app-collapse>

          <b-row class="mt-2">
            <b-col md="2">
              <b-button variant="primary" type="submit" class="btn-block mt-1">
                Add Item <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>

            <b-col md="2">
              <b-button
                class="btn-block revivify-success mt-1"
                @click="submitForm('save')"
              >
                Submit Item
              </b-button>
            </b-col>

            <b-col md="2">
              <b-button
                variant="secondary"
                class="btn-block mt-1"
                @click="draftForm('draft')"
              >
                Save as Draft
              </b-button>
            </b-col>
          </b-row>

          <!-- Sixe Chart Modal -->
          <b-modal
            ref="ref-modal-lg-choose-celebrity"
            id="modal-lg-choose-celebrity"
            cancel-variant="primary"
            ok-only
            ok-title="Save"
            centered
            size="md"
            title="Choose Celebrity"
          >
            <b-form-input
              type="text"
              placeholder="Search/Enter Celebrity"
              v-model="keyword"
            />

            <b-table
              id="CelebrityListTableId"
              ref="refCelebrityListTableId"
              :items="celebrityList"
              responsive
              :fields="celebrityColumns"
              primary-key="name"
              class="position-relative celebrity_table"
              :keyword="keyword"
            >
              <!-- @click="chooseCelebrity(data.item)" -->
              <template #cell(name)="data">
                <span class="text-black">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    class="choose_btn"
                    @click="chooseCelebrity(data.item)"
                  >
                    {{ data.item }}
                  </b-button>
                </span>
              </template>
            </b-table>
          </b-modal>

          <!-- Sixe Chart Modal -->
          <b-modal
            id="modal-lg-size-chart"
            cancel-variant="primary"
            ok-only
            ok-title="OK"
            centered
            size="lg"
            title="Size Chart"
            >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsam
            repellendus possimus earum nisi quae adipisci explicabo molestiae
            velit excepturi fuga corporis et consequuntur quasi blanditiis,
            maiores commodi sequi quam facilis.

            <b-img
              src="https://assets.myntassets.com/assets/images/sizechart/2016/12/12/11481538267795-footwear.png"
            />
          </b-modal>

          <!-- Condition Legend - Modal -->
          <b-modal
            id="condition-modal"
            cancel-variant="secondary"
            ok-only
            ok-title="OK"
            centered
            size="lg"
            title="Condition Legend"
          >
            <b-table class="mt-2" responsive="sm" :items="condition_items" />
          </b-modal>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCard,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BFormTextarea,
  BFormRadio,
  BInputGroup,
  BButtonGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormDatepicker,
  BImg,
  BFormFile,
  BCollapse,
  VBToggle,
  VBPopover,
  BFormSelect,
  BFormSelectOption,
  BCardBody,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import ProductServices from "@/apiServices/ProductServices";
import axios from "axios";
import storageService from "@/apiServices/storageService";
import DropDownServices from "@/apiServices/DropDownServices";
import { country_code } from "../Address/countries_json";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserManagementServices from "@/apiServices/UserManagementServices";
import { designers } from "./designers";
import { FILESURL, STRIPE_PUB_KEY } from "@/config/index";
import { loadScript } from "@/libs/helpers";
import MasterServices from "@/apiServices/MasterServices";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BCardBody,
    BCard,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormTextarea,
    BFormRadio,
    BButtonGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BImg,
    BFormFile,
    BCollapse,
    BFormSelect,
    VBPopover,
    VBToggle,
    vSelect,
    BFormSelectOption,
    AppCollapse,
    AppCollapseItem,
    Loading,
    BAlert,
    BFormCheckbox,
  },

  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
  },

  computed: {
    celebrityList() {
      return this.keyword
        ? this.celebrities.filter((item) => {
            if (item) {
              if (
                item
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase())
              ) {
                return true;
              } else {
                return false;
              }
            }
          })
        : this.celebrities;
    },
  },

  data() {
    const conditions = ["New", "Excellent", "Very Good", "Good", "Fair"];

    const currencies = ["£", "$", "₹"];

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    minDate.setHours(minDate.getHours() + 336);

    return {
      mainCategory: ["Men", "Women", "Fashion Jewellery", "Accessories"],
      subCategoryMen: [
        "Kurtas",
        "Bandis",
        "Sherwanis",
        "Shoes",
        "Shirts",
        "Suits",
        "Bandhgalas",
        "Mens Jewellery",
      ],
      subCategoryFJ: [
        "Earrings",
        "Necklaces",
        "Rings",
        "Tikkas & Passas",
        "Bangles & Bracelets",
        "Brooches",
        "Nose rings",
        "Hand harness",
        "Head harness",
      ],
      subCategoryWomen: [
        "Lehengas",
        "Anarkalis",
        "Kurtas",
        "Shararas",
        "Saris",
        "Gowns",
        "Dresses",
        "Tops",
        "Kaftans",
        "Jumpsuits",
        "Skirts",
        "Jackets",
        "Capes",
        "Beachwear",
        "Co-ords",
      ],
      subCategoryAcc: ["Shawls", "Bags", "Belts"],
      currentSubCategory: [],

      collapseType: "margin",

      STRIPE_PUB_KEY,
      confirmParams: {
        return_url: window.location.href, // success url
      },

      /* Fullscreen Loader Start*/
      status: false,
      isLoading: false,
      /* Fullscreen Loader Ends*/

      countries: [],
      city_list: [],
      cityModel: "",
      coutryCodeSelected: "",
      countryCode: country_code,

      countryModel: "",
      cityModel: "",
      countrySelectedName: "",

      cloth_sizes: ["XXS", "XS", "S", "M", "L", "XL", "XXL"],
      charities: [
        "Wellcome Trust",
        "Azim Premji Foundation",
        "Mastercard Foundation",
        "Lilly Endowment",
        "Ford Foundation",
      ],
      celebrities: [
        "Joe Biden",
        "Cristiano Ronaldo",
        "Robert Downey",
        "Jeff Bezos",
        "Kylie Jenner",
      ],

      celebrityColumns: [
        { key: "name", label: "Celebrity Name", sortable: false },
      ],
      keyword: "",
      select_celebrity: "Choose Cebelrity",

      item_forms: [
        {
          itemType: "sell",
          mainCategorySelected: "",
          typeSelected: "",
          designerSelected: "",
          conditionSelected: "",
          color: "",
          countryModel: "",
          cityModel: "",
          currenciesAmountSelected: null,
          currenciesSymbolSelected: null,
          amount: "",
          date_of_purchase: "",
          product_description: "",
          service_id: "",
          service: null,
          service_base_price: 0,
          service_converted_price: 0,
          currenciesBudgetSelected: "£",
          budget: "",
          delivery_date: "",
          images: {
            imgF: null,
            imgB: null,
            imgS: null,
            imgL: null,
            imgD: null,
          },
          frontImage: "",
          backImage: "",
          insideImage: "",
          labelImage: "",
          defectImage: "",
          currentSymbol: "",
          receipt: "",

          charity: "",
          celebrity: "",

          charitySelected: false,
          celebritySelected: false,

          frontImageRequired: null,
          backImageRequired: null,
          sideImageRequired: null,
          labelImageRequired: null,
        },
      ],
      nextItemId: 1,

      //itemType: "sell", //Sell - Refubishment
      designers, //ARRAY Designers
      conditions, //ARRAY - GOOD, OK, GREAT
      currencies,
      sellIsRequired: true,
      refurbishIsRequired: false,
      min: minDate,
      colors: [
        {
          title: "White",
          icon: "CircleIcon",
          color_code: "#ffffff",
        },
        {
          title: "Black",
          icon: "CircleIcon",
          color_code: "#000000",
        },
        {
          title: "Blue",
          icon: "CircleIcon",
          color_code: "#326FDA",
        },
        {
          title: "Brown",
          icon: "CircleIcon",
          color_code: "#7E4E38",
        },
        {
          title: "Burgundy",
          icon: "CircleIcon",
          color_code: "#953E47",
        },
        {
          title: "Cream",
          icon: "CircleIcon",
          color_code: "#ECEAC1",
        },
        {
          title: "Ecru",
          icon: "CircleIcon",
          color_code: "#DED4C8",
        },
        {
          title: "Gold",
          icon: "CircleIcon",
          color_code: "#AF812C",
        },
        {
          title: "Gray",
          icon: "CircleIcon",
          color_code: "#949494",
        },
        {
          title: "Red ",
          icon: "CircleIcon",
          color_code: "#F40002",
        },
        {
          title: "Green ",
          icon: "CircleIcon",
          color_code: "#596750",
        },
        {
          title: "Ivory",
          icon: "CircleIcon",
          color_code: "#FFFFF0",
        },
        {
          title: "Neutrals ",
          icon: "CircleIcon",
          color_code: "#E6C3AF",
        },
        {
          title: "Orange",
          icon: "CircleIcon",
          color_code: "#F08213",
        },
        {
          title: "Pink",
          icon: "CircleIcon",
          color_code: "#F058A3",
        },
        {
          title: "Purple",
          icon: "CircleIcon",
          color_code: "#803EAC",
        },
        {
          title: "Silver",
          icon: "CircleIcon",
          color_code: "#C0C0C0",
        },
        {
          title: "Yellow",
          icon: "CircleIcon",
          color_code: "#FFFF96",
        },
        {
          title: "Animal Print",
          icon: "CircleIcon",
          color_code: "#111111",
        },
      ],
      condition_items: [
        {
          title: "New",
          description:
            "Never used, stored perfectly, no signs of wear or tear. May come with original tags.",
        },
        {
          title: "Excellent",
          description:
            "Appears unworn. Hardly ever been used and nearly no signs of wear.",
        },
        {
          title: "Very Good",
          description:
            "Minimal use with small signs of wear and tear that are only visible up close.",
        },
        {
          title: "Good",
          description:
            "Some signs of use, slight staining and wear at borders or rims.",
        },
        {
          title: "Fair",
          description:
            "Visible usage, minor stains, wear and tear but good enough to wear.",
        },
      ],
      tempCurrenciesSymbol: null,
      //currentSymbol: "£",
      //service_base_price: 0,

      clothType: [],
      designersList: [],
      conditionsList: [],

      type: "FRONT",
      imgIndex: "",

      userType: "",
      sellerId: 0,
      sellerEmail: "",
      sellerVerified: false,

      imagesValidation: null,

      userInfo: null,

      gridData: [
        {
          name: "Chuck Norris",
          power: Infinity,
        },
        {
          name: "Bruce Lee",
          power: 9000,
        },
        {
          name: "Jackie Chan",
          power: 7000,
        },
        {
          name: "Jet Li",
          power: 8000,
        },
      ],
    };
  },

  watch: {
    "$store.state.home.currency": {
      handler: function (val) {
        if (this.$store.state.home.currency.length > 0) {
          this.item_forms[0].currenciesAmountSelected =
            this.$store.state.home.currency[1].c_id;
          this.item_forms[0].currenciesSymbolSelected =
            this.$store.state.home.currency[1].c_symbol;
        }
      },
      deep: true,
      immediate: true,
    },

    currenciesAmountSelected: function (oldVal, newVal) {
      if (this.amount) {
        let oldCurrencyObj = this.getCurrencyObj(oldVal);
        let newCurrencyObj = this.getCurrencyObj(newVal);

        let amt = parseInt(this.amount);
        let rupee_conversion =
          amt / parseInt(oldCurrencyObj.c_rupee_conversion);
        let new_amt =
          rupee_conversion * parseInt(newCurrencyObj.c_rupee_conversion);

        this.amount = Math.round(new_amt);
      }
    },
  },

  methods: {
    chooseCelebrity(celeb_name) {
      if (celeb_name != "") {
        this.keyword = celeb_name;
      } else {
        this.keyword = "Choose Celebrity";
      }
      this.$refs["ref-modal-lg-choose-celebrity"].hide();
    },

    repeateAgain() {
      let new_form = {
        itemType: "sell",
        mainCategorySelected: "",
        typeSelected: "",
        designerSelected: "",
        conditionSelected: "",
        color: "",
        countryModel: "",
        cityModel: "",
        currenciesAmountSelected: this.$store.state.home.currency[1].c_id,
        currenciesSymbolSelected: this.$store.state.home.currency[1].c_symbol,
        amount: "",
        date_of_purchase: "",
        product_history: "",
        service_id: "",
        service: null,
        service_base_price: 0,
        service_converted_price: 0,
        currenciesBudgetSelected: "£",
        budget: "",
        delivery_date: "",
        images: {
          imgF: null,
          imgB: null,
          imgS: null,
          imgL: null,
          imgD: null,
        },
        frontImage: "",
        backImage: "",
        insideImage: "",
        labelImage: "",
        defectImage: "",
        currentSymbol: "",
        receipt: "",

        charity: "",
        celebrity: "",

        charitySelected: false,
        celebritySelected: false,

        frontImageRequired: null,
        backImageRequired: null,
        sideImageRequired: null,
        labelImageRequired: null,
      };

      this.item_forms.push(new_form);
    },

    removeItem(index) {
      this.item_forms.splice(index, 1);
    },

    setImgType(type, index) {
      this.type = type;
      this.imgIndex = index;
      this.$refs["image-input"].click();
    },

    loadDefault() {
      let profileData = storageService.UserData.getUserProfile();
      let uid = JSON.parse(profileData);

      this.userInfo = uid;

      if (uid.user_role_id == 1) {
        this.userType = "SELLER";
      }

      if (uid.user_verified == 1) {
        this.sellerVerified = true;
      } else {
        this.sellerVerified = false;
      }

      console.log("uid: ", { uid });
    },

    async loadCountry() {
      let response = await DropDownServices.loadAllCountries();
      console.log(response, "loadCountry");
      this.countries = response.data;
    },

    async showCity(country_obj) {
      if (country_obj != null) {
        this.city_list = [];
        this.cityModel = "";
        this.isLoading = true;
        let response = await DropDownServices.loadCity();
        this.city_list = response.data.filter((x) => {
          return x.country_id == country_obj.id;
        });
        this.isLoading = false;
      }
    },

    /* loadItemType() {
      DropDownServices.loadItemType()
        .then((response) => {
          this.clothType = response.data.data;
          this.loadDefault();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    }, */

    async loadDropdowns(tag_type_name) {
      try {
        const response = await MasterServices.getUserTags({ tag_type_name });
        if (response.data.status) {
          if (tag_type_name == "ItemType") {
            this.clothType = response.data.data;
          } else if (tag_type_name == "Designer") {
            this.designersList = response.data.data;
          } else if (tag_type_name == "Condition") {
            this.conditionsList = response.data.data;
          }
        }
      } catch (err) {
        console.log("Error in getting all tags ", err);
      }
    },

    beforeMountFun() {
      this.loadDefault();
      this.loadDropdowns("ItemType");
      this.loadDropdowns("Designer");
      this.loadDropdowns("Condition");
      this.loadCountry();
    },

    changeItemType(type, index) {
      this.item_forms[index].itemType = type;
    },

    uploadNewImage(event) {
      let type = this.type;
      let imgIndex = this.imgIndex;

      let URL = FILESURL;

      let data = new FormData();
      event.target.files.forEach((item) => {
        data.append("doc", item);
      });

      let token = localStorage.getItem("token");
      if (token) {
        axios
          .put(URL, data, {
            headers: {
              Authorization: token,
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            let img_arr = response.data.data;
            if (img_arr && img_arr.length > 0) {
              for (let i = 0; i < img_arr.length; i++) {
                const item = img_arr[i];

                //this.item_forms[imgIndex].images.push(item);
                //console.log(this.item_forms[imgIndex].images);

                if (type == "FRONT") {
                  this.item_forms[imgIndex].frontImage = item;
                  this.item_forms[imgIndex].images.imgF = item;
                } else if (type == "BACK") {
                  //this.backImage = item;
                  this.item_forms[imgIndex].backImage = item;
                  this.item_forms[imgIndex].images.imgB = item;
                } else if (type == "INSIDE") {
                  //this.insideImage = item;
                  this.item_forms[imgIndex].insideImage = item;
                  this.item_forms[imgIndex].images.imgS = item;
                } else if (type == "LABEL") {
                  //this.labelImage = item;
                  this.item_forms[imgIndex].labelImage = item;
                  this.item_forms[imgIndex].images.imgL = item;
                } else if (type == "DEFECT") {
                  //this.defectImage = item;
                  this.item_forms[imgIndex].defectImage = item;
                  this.item_forms[imgIndex].images.imgD = item;
                }
              }
            }
          });
      } else {
        console.log("Auth Token not found ");
      }
    },

    removeNewImage(type, index) {
      if (type == "FRONT") {
        this.item_forms[index].frontImage = "";
        this.item_forms[index].images.imgF = null;
      }

      if (type == "BACK") {
        this.item_forms[index].backImage = "";
        this.item_forms[index].images.imgB = null;
      }

      if (type == "INSIDE") {
        this.item_forms[index].insideImage = "";
        this.item_forms[index].images.imgS = null;
      }

      if (type == "LABEL") {
        this.item_forms[index].labelImage = "";
        this.item_forms[index].images.imgL = null;
      }

      if (type == "DEFECT") {
        this.item_forms[index].defectImage = "";
        this.item_forms[index].images.imgD = null;
      }
    },

    uploadReceipt(event, index) {
      let URL = FILESURL;

      let data = new FormData();
      event.target.files.forEach((item) => {
        data.append("doc", item);
      });

      let token = localStorage.getItem("token");
      if (token) {
        axios
          .put(URL, data, {
            headers: {
              Authorization: token,
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            let img_arr = response.data.data;
            if (img_arr && img_arr.length > 0) {
              for (let i = 0; i < img_arr.length; i++) {
                const item = img_arr[i];

                //this.images.push(item);
                console.log(item);

                this.item_forms[index].receipt = item;
              }
            }
          });
      } else {
        console.log("Auth Token not found ");
      }
    },

    submitForm(type) {
      /* console.log(this.item_forms);
      return; */

      this.item_forms.forEach((element, index) => {
        if (element.frontImage == "") {
          console.log(index + "Front Image Required");
          this.item_forms[index].frontImageRequired = true;
          this.imagesValidation1 = true;
        } else {
          this.item_forms[index].frontImageRequired = false;
          this.imagesValidation = false;
        }

        if (element.backImage == "") {
          console.log(index + "Back Image Required");
          this.item_forms[index].backImageRequired = true;
          this.imagesValidation = true;
        } else {
          this.item_forms[index].backImageRequired = false;
          this.imagesValidation = false;
        }

        if (element.insideImage == "") {
          console.log(index + "Side Image Required");
          this.item_forms[index].sideImageRequired = true;
          this.imagesValidation = true;
        } else {
          this.item_forms[index].sideImageRequired = false;
          this.imagesValidation = false;
        }

        if (element.labelImage == "") {
          console.log(index + "Label Image Required");
          this.item_forms[index].labelImageRequired = true;
          this.imagesValidation = true;
        } else {
          this.item_forms[index].labelImageRequired = false;
          this.imagesValidation = false;
        }
      });

      for (let index = 0; index < this.item_forms.length; index++) {
        const element = this.item_forms[index];

        if (element.frontImage == "") {
          this.imagesValidation = true;
          this.toastImage("Front Image", index + 1);
          return false;
        } else if (element.backImage == "") {
          this.imagesValidation = true;
          this.toastImage("Back Image", index + 1);
          return false;
        } else if (element.insideImage == "") {
          this.imagesValidation = true;
          this.toastImage("Side Image", index + 1);
          return false;
        } else if (element.labelImage == "") {
          this.imagesValidation = true;
          this.toastImage("Label Image", index + 1);
          return false;
        }
      }

      this.$refs.itemFormsValidator.validate().then((success) => {
        if (success) {
          let profileData = storageService.UserData.getUserProfile();
          let uid = JSON.parse(profileData);

          if (uid.user_role_id == 1) {
            this.sellerId = uid.user_id;
            this.sellerEmail = uid.user_email;
          }

          let senData = {
            productId: null,
            sellerId: this.sellerId,
            sellerEmail: this.sellerEmail,
            itemDetails: this.item_forms,
            itemSaveType: type,
          };

          // console.log("senData:", senData);
          // return;

          ProductServices.SaveProduct(senData)
            .then((response) => {
              if (response.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Item Saved Successfully!",
                    icon: "BoxIcon",
                    variant: "success",
                  },
                });

                this.$router.push("/product_list");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Failed ot created Item",
                    icon: "BoxIcon",
                    variant: "error",
                  },
                });
              }
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        }
      });
    },

    draftForm(type) {
      console.log(this.item_forms);

      let profileData = storageService.UserData.getUserProfile();
      let uid = JSON.parse(profileData);

      if (uid.user_role_id == 1) {
        this.sellerId = uid.user_id;
        this.sellerEmail = uid.user_email;
      }

      let senData = {
        productId: null,
        sellerId: this.sellerId,
        sellerEmail: this.sellerEmail,
        itemDetails: this.item_forms,
        itemSaveType: type,
      };

      ProductServices.SaveProduct(senData)
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Item Saved Successfully!",
                icon: "BoxIcon",
                variant: "success",
              },
            });

            this.$router.push("/product_list");
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed ot created Item",
                icon: "BoxIcon",
                variant: "error",
              },
            });
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    compareServiceAmount() {
      const service_amount = this.service.service_base_price;
      const buddget_amount = this.budget;
      if (service_amount < buddget_amount) {
        return true;
      } else {
        return false;
      }
    },

    setServiceId(e, index) {
      if (e) this.item_forms[index].service_id = e.service_id;
      //console.log(this.service_id);
    },

    getCurrencyObj(c_id) {
      let currency_arr = this.$store.state.home.currency;
      if (currency_arr.length > 0) {
        for (let i = 0; i < currency_arr.length; i++) {
          const currency = currency_arr[i];
          if (currency.c_id === c_id) {
            return currency;
          }
        }
      }
    },

    getSelectedCurrency(data, index) {
      console.log(data, this.$store.state.home.currency);
      let cid = data;
      let allCurrencies = this.$store.state.home.currency; //this.tempCurrenciesSymbol;
      let resCurrency = allCurrencies.filter((x) => {
        return x.c_id == cid;
      });
      this.item_forms[index].currentSymbol = resCurrency[index].c_symbol;
      //console.log(this.currentSymbol);
    },

    chooseMaincategory(data) {
      this.currentSubCategory = [];
      if (data == "Men") {
        this.currentSubCategory = this.subCategoryMen;
      } else if (data == "Women") {
        this.currentSubCategory = this.subCategoryWomen;
      } else if (data == "Accessories") {
        this.currentSubCategory = this.subCategoryAcc;
      } else if (data == "Fashion Jewellery") {
        this.currentSubCategory = this.subCategoryFJ;
      } else {
        this.currentSubCategory = [];
      }
    },

    getSuffix(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    toastImage(image_type, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: image_type + " is required in Item form " + position,
          icon: "BoxIcon",
          variant: "error",
        },
      });
    },

    convertCurrency(index) {
      let getCurrency = this.item_forms[index].currenciesBudgetSelected;

      if (getCurrency == "£") {
        this.item_forms[index].service_converted_price = Math.round(
          this.item_forms[index].service.service_base_price
        ).toFixed(2);
      } else if (getCurrency == "$") {
        this.item_forms[index].service_converted_price = Math.round(
          this.item_forms[index].service.service_base_price * 1.23456
        ).toFixed(2);
      } else if (getCurrency == "₹") {
        this.item_forms[index].service_converted_price = Math.round(
          this.item_forms[index].service.service_base_price * 95
        ).toFixed(2);
      }
    },

    getBasePrice(type) {
      // type = USDINR / GBPUSD / GPBINR
      const datetime = Date.now();
      const url =
        "https://query1.finance.yahoo.com/v8/finance/chart/" +
        type +
        "=X?symbol=" +
        type +
        "%3DX&period1=" +
        (datetime - 60 * 86400) +
        "&period2=" +
        datetime +
        "&interval=1d&includePrePost=false&events=div%7Csplit%7Cearn&lang=en-US&region=US&corsDomain=finance.yahoo.com";
      axios.post(url).then((response) => {
        console.log("getBasePrice: ", response);
      });
    },

    onCancel() {
      console.log("Seller cancelled the loader.");
    },
  },

  beforeMount() {
    this.beforeMountFun();
    //this.getBasePrice("USDINR");
    this.$store.dispatch("home/setCurrency");
    this.$store.dispatch("home/setServices");
  },
};
</script>

<style>
.item_forms_card > .collapse-margin > .card {
  border-bottom: 1px dashed #9a9a9a !important;
}

.info_icon_1 {
  transform: translateY(30%) !important;
}

.revivify-success {
  background-color: #3a4b43 !important;
}

.item_forms_card {
  border: 1px solid #3a4b43;
  border-radius: 20px;
}

.color-icon {
  border: 1px solid black !important;
  border-radius: 100px !important;
}

.choose-images-card {
  border: #ccc 1px dashed;
  text-align: center;
  color: #666;
  transition: all 0.3s ease;
  height: 210px;
  width: 100%;
  position: relative;
  border-radius: 8px !important;
  background-color: #f8f8f8 !important;
  margin-bottom: 15px !important;
}

.choose-images-card h5 {
  display: flex;
  font-size: 12px;
  color: #adadad;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.choose-images-card input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  opacity: 0;
  /* -ms-filter: 'alpha(opacity=0)'; */
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.choose-images-card img {
  width: 100%;
  margin: 0px;
  height: 100%;
  padding: 2px;
  /* border-radius: 12px !important; */
}

.choose-images-card span.delete {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  align-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  z-index: 342234;
}

.choose-images-card .card-body {
  padding: 0px !important;
}

span.delete .remove-icon {
  background: rgb(238, 238, 238, 0.75);
  padding: 20px;
  cursor: pointer;
}

.prod-image {
  width: 100% !important;
}

.notify-badge {
  position: absolute;
  right: -20px;
  top: 10px;
  background: red;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 20px;
}

.custom-container {
  position: relative;
}

.info-icon {
  position: absolute;
  top: 0;
  right: 0;
  top: 50%;
  margin-right: 10px;
  transform: translateY(-50%);
  z-index: 1;
}

.size-chart {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid black;
  margin-left: 20px;
  font-weight: 600;
  font-size: 14px;
  color: #ff8100;
  cursor: pointer;
  border-radius: 4px;
}

.custom-flex {
  display: -webkit-box;
}

.per-page-selector {
  width: 90px;
}

.vs__dropdown-toggle > .vs__open-indicator {
  fill: grey !important;
}

[dir="ltr"] .vs__actions > svg {
  fill: grey !important;
}

.position-relative {
  max-height: 220px !important;
  height: 220px !important;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-bottom: 0px;
}

table#CelebrityListTableId thead tr th {
  display: none;
}

table#CelebrityListTableId tbody tr td {
  padding: 2px;
}

.choose_btn {
  width: 100%;
  text-align: left;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
